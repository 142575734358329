import {StyleExtractor} from "@kubric/utils";
import React from "react";

import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const FGBuffer = ({buffer = 0, mobileBuffer = 0}) => {
  return (
    <>
      <div
        className={styler.get("hideInMobile", "full-bleed")}
        style={{backgroundColor: "#f3f6f6", height: buffer}}
      />
      <div
        className={styler.get("hideInDesktop", "full-bleed")}
        style={{backgroundColor: "#f3f6f6", height: mobileBuffer}}
      />
    </>
  );
};

export default FGBuffer;
