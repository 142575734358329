// extracted by mini-css-extract-plugin
export var authorImage = "styles-module--authorImage--nF3NI";
export var authors = "styles-module--authors--bShl8";
export var authorsHeading = "styles-module--authorsHeading--2Y0OM";
export var authorsWrap = "styles-module--authorsWrap--aKjYe";
export var cardAboveTitleText = "styles-module--cardAboveTitleText--3NQDC";
export var cardCTAText = "styles-module--cardCTAText--2iHTc";
export var cardTitle = "styles-module--cardTitle--2Ha34";
export var chapterInfoSection = "styles-module--chapterInfoSection--29nOG";
export var content = "styles-module--content--1BG-s";
export var firstNumber = "styles-module--firstNumber--3C1kS";
export var firstText = "styles-module--firstText--1ef6w";
export var firstWrap = "styles-module--firstWrap--2jLph";
export var rightContentWrap = "styles-module--rightContentWrap--3fdKV";
export var secondNumber = "styles-module--secondNumber--1MvPl";
export var secondText = "styles-module--secondText--1Zn9I";
export var secondWrap = "styles-module--secondWrap--1qVCM";
export var textAboveContent = "styles-module--textAboveContent--28op4";
export var tools = "styles-module--tools--1BiSj";
export var toolsCoveredSection = "styles-module--toolsCoveredSection--1ClQz";
export var toolsHeading = "styles-module--toolsHeading--2UHRF";
export var topicCard = "styles-module--topicCard--3ef76";
export var topicsCovered = "styles-module--topicsCovered--3Z5Eq";
export var topicsCoveredHeading = "styles-module--topicsCoveredHeading--k-JPr";
export var topicsCoveredSection = "styles-module--topicsCoveredSection--1A9sV";
export var wyg = "styles-module--wyg--1j-za";
export var wygContent = "styles-module--wygContent--1Fhsc";
export var wygHeading = "styles-module--wygHeading--2oJYb";