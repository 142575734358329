import {StyleExtractor} from "@kubric/utils";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import ArrowRight from "react-feather/dist/icons/arrow-right";

import useCurrentFieldGuideSlugs from "../../../../../hooks/useCurrentFieldGuideSlugs";
import SanityRichText from "../../../../commons/SanityRichText";
import FGToolCard from "../../FGToolCard";
import * as styles from "./styles.module.scss";

const RightContent = ({data}) => {
  const styler = new StyleExtractor(styles);
  const {
    authors,
    chapterAuthorsHeading,
    topics = [],
    topicsCoveredHeading,
    textAboveContent,
    tools = [],
    toolsHeading,
    wyg: {firstNumber, firstText, secondNumber, secondText},
    wygHeading,
    _rawContent,
  } = data;

  const {base, chapter} = useCurrentFieldGuideSlugs();

  const showTopicsSection = topics && topics.length > 0;
  const showToolsSection = tools && tools.length > 0;

  return (
    <div className={styler.get("rightContentWrap")}>
      <span className={styler.get("textAboveContent")}>{textAboveContent}</span>
      <SanityRichText
        renderContainerOnSingleChild
        blocks={_rawContent}
        className={styler.get("content")}
      />

      {/* Chapter Info section */}
      <div className={styler.get("chapterInfoSection")}>
        <div className={styler.get("authorsWrap")}>
          <span className={styler.get("authorsHeading")}>
            {chapterAuthorsHeading}
          </span>
          <div className={styler.get("authors")}>
            {authors.map(({image}) => (
              <GatsbyImage
                className={styler.get("authorImage")}
                alt={image?.alt || "author image"}
                image={image?.image?.asset?.gatsbyImageData}
                objectFit='contain'
              />
            ))}
          </div>
        </div>
        <div className={styler.get("wyg")}>
          <span className={styler.get("wygHeading")}>{wygHeading}</span>
          <div className={styler.get("wygContent")}>
            <div className={styler.get("firstWrap")}>
              <span className={styler.get("firstNumber")}>{firstNumber}</span>
              <span className={styler.get("firstText")}>{firstText}</span>
            </div>
            <div className={styler.get("secondWrap")}>
              <span className={styler.get("secondNumber")}>{secondNumber}</span>
              <span className={styler.get("secondText")}>{secondText}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Topics Covered Section */}
      {showTopicsSection && (
        <div className={styler.get("topicsCoveredSection")}>
          <span className={styler.get("topicsCoveredHeading")}>
            {topicsCoveredHeading}
          </span>
          <div className={styler.get("topicsCovered")}>
            {topics.map(({cardAboveTitleText, cardCTAText, navTitle, slug}) => (
              <Link
                className={styler.get("topicCard")}
                to={`/${base}/${chapter}/${slug.current}/`}
              >
                <span className={styler.get("cardAboveTitleText")}>
                  {cardAboveTitleText}
                </span>
                <span className={styler.get("cardTitle")}>{navTitle}</span>
                <span className={styler.get("cardCTAText")}>
                  {cardCTAText}
                  <ArrowRight />
                </span>
              </Link>
            ))}
          </div>
        </div>
      )}

      {/* Tools in this chapter Section */}
      {showToolsSection && (
        <div className={styler.get("toolsCoveredSection")}>
          <span className={styler.get("toolsHeading")}>{toolsHeading}</span>
          <div className={styler.get("tools")}>
            {tools.map((tool) => (
              <FGToolCard {...tool} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RightContent;
