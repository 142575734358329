import {graphql} from "gatsby";
import React from "react";

import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import FGBuffer from "../../components/pages/fieldGuide/FGBuffer";
import Main from "../../components/pages/fieldGuide/Main";
import BottomCTA from "../../components/pages/fieldGuide/chapterPage/BottomCTA";
import RightContent from "../../components/pages/fieldGuide/chapterPage/RightContent";
import "../../pages/styles.scss";

const FieldGuideChapterPage = ({data, pageContext: {data: cmsData}}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];
  const {stickyNav, bottomCTA} = cmsData;

  return (
    <Layout
      type='modemagic'
      mmv3
      seoData={seoData}
      headerProps={{showMMCta: true, alternateBg: true}}
    >
      <StructuredData />
      <Buffer buffer={66} mobileBuffer={30} />
      <FGBuffer buffer={70} mobileBuffer={30} />
      <Main
        stickyNav={stickyNav}
        rightContent={<RightContent data={cmsData} />}
      />
      <BottomCTA data={bottomCTA} />
    </Layout>
  );
};

export const query = graphql`
  query ($tag: String) {
    allSanitySeoMeta(filter: {page: {eq: $tag}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default FieldGuideChapterPage;
