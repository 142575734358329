import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";
import ArrowRight from "react-feather/dist/icons/arrow-right";

import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const FGToolCard = ({
  aboveTitle,
  ctaText,
  description,
  idAttribute,
  link,
  title,
}) => {
  return (
    <OutboundLink
      className={styler.get("toolCard")}
      id={idAttribute}
      href={link}
    >
      <span className={styler.get("cardAboveTitleText")}>{aboveTitle}</span>
      <span className={styler.get("cardTitle")}>{title}</span>
      {description && (
        <span className={styler.get("cardDescription")}>{description}</span>
      )}
      <span className={styler.get("cardCTAText")}>
        {ctaText}
        <ArrowRight />
      </span>
    </OutboundLink>
  );
};

export default FGToolCard;
